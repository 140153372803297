/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

export function onRouteUpdate({ location, prevLocation }) {
  const url = location && location.pathname + location.search + location.hash

  if (location && prevLocation) {
    const event = document.createEvent(`Event`)
    event.initEvent(`onRouteChange`, true, true)
    window.dispatchEvent(event)
  }

  // matomo tracking
  if (window._paq) {
    const prevUrl =
      prevLocation &&
      prevLocation.pathname + prevLocation.search + prevLocation.hash

    setTimeout(() => {
      if (prevUrl) window._paq.push([`setReferrerUrl`, prevUrl])
      window._paq.push([`setCustomUrl`, url])
      window._paq.push([`setDocumentTitle`, document.title])
      window._paq.push([`trackPageView`])
      window._paq.push([`enableLinkTracking`])
      window._paq.push([`trackAllContentImpressions`])
    }, 50)
  }

  // hubspot tracking
  /* if (window._hsq && location && prevLocation) {
    window._hsq.push([`setPath`, url])
    window._hsq.push([`trackPageView`])
  } */
}

export function onClientEntry() {
  setInterval(() => window._paq && window._paq.push([`ping`]), 5000)

  // matomo: track clicks
  document.addEventListener(`click`, (e) => {
    if (window._paq) {
      const el = e.target.closest(`[data-matomo]`)
      if (el && el.dataset.matomo) {
        const data = JSON.parse(el.dataset.matomo).filter(Boolean)
        if (data.length) {
          window._paq.push([`trackEvent`, ...data])
        }
      }
    }
  })
}
